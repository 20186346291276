import { Injectable } from '@angular/core';

import $ from 'jquery';
import { BehaviorSubject, Observable } from 'rxjs';
import { Loader } from '../models/loader.model';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    constructor() {
        this.loader = new Loader();
        this.loader.show = false;
        this.loaderSubject = new BehaviorSubject<Loader>(this.loader);
        this.currentLoader = this.loaderSubject.asObservable();
    }

    public loader: Loader;
    public uploader = false;
    public progress: number;

    private loaderSubject: BehaviorSubject<Loader>;
    public currentLoader: Observable<Loader>;

    private static heightLoaderBackdrop() {
        const h = $(document).height() - $('header').height();
        $('.loader-backdrop').height(h);
    }

    public show(): void {
        LoaderService.heightLoaderBackdrop();
        this.loader.show = true;
        this.loaderSubject.next(this.loader);
    }

    public hide(): void {
        this.uploader = false;
        this.loader.show = false;
        this.loaderSubject.next(this.loader);
    }
}
